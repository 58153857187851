var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('div',{staticClass:"d-flex pa-0 align-center"},[(_vm.hide)?_c('h1',[_vm._v(" Session Details ")]):_vm._e(),_c('v-spacer'),_c('v-spacer')],1),(_vm.session)?_c('v-card',{staticClass:"mt-2"},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[(_vm.session.device)?_c('v-toolbar-title',[(_vm.session.active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" check_circle ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" check_circle ")])]}}],null,false,128765253)},[_c('span',[_vm._v("active "+_vm._s(_vm._f("lastSeen")(_vm.session.last_seen)))])]),_vm._v(" "+_vm._s(_vm.session.device.name)+" ")],1):_vm._e(),_c('v-spacer'),(_vm.session.recorded)?_c('v-menu',{ref:"menu",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g({attrs:{"color":"transparent"}},on),[_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,false,101686188)},[_c('v-card',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasAuthorizationPlay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(_vm.session.recorded && _vm.isEnterprise)?_c('v-list-item',{attrs:{"disabled":!_vm.hasAuthorizationPlay},on:{"click":function($event){$event.stopPropagation();return _vm.openDialog('sessionPlayDialog')}}},[_c('SessionPlay',{attrs:{"uid":_vm.session.uid,"recorded":_vm.session.authenticated && _vm.session.recorded,"show":_vm.sessionPlayDialog,"data-test":"sessionPlay-component"},on:{"update:show":function($event){_vm.sessionPlayDialog=$event}}})],1):_vm._e()],1)]}}],null,false,2897529141)},[_c('span',[_vm._v(" You don't have this kind of authorization. ")])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasAuthorizationClose},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(_vm.session.active)?_c('v-list-item',{attrs:{"disabled":!_vm.hasAuthorizationClose},on:{"click":function($event){$event.stopPropagation();return _vm.openDialog('sessionCloseDialog')}}},[_c('SessionClose',{attrs:{"uid":_vm.session.uid,"device":_vm.session.device_uid,"show":_vm.sessionCloseDialog,"data-test":"sessionClose-component"},on:{"update:show":function($event){_vm.sessionCloseDialog=$event},"update":_vm.refresh}})],1):_vm._e()],1)]}}],null,false,912820126)},[_c('span',[_vm._v(" You don't have this kind of authorization. ")])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasAuthorizationRemoveRecord},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(_vm.session.recorded)?_c('v-list-item',{attrs:{"disabled":!_vm.hasAuthorizationRemoveRecord},on:{"click":function($event){$event.stopPropagation();return _vm.openDialog('sessionDeleteRecord')}}},[_c('SessionDeleteRecord',{attrs:{"uid":_vm.session.uid,"show":_vm.sessionDeleteRecord,"data-test":"sessionDeleteRecord-component"},on:{"update:show":function($event){_vm.sessionDeleteRecord=$event},"update":_vm.refresh}})],1):_vm._e()],1)]}}],null,false,1077421704)},[_c('span',[_vm._v(" You don't have this kind of authorization. ")])])],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"overline"},[_vm._v(" Uid ")]),_c('div',{attrs:{"data-test":"sessionUid-field"}},[_vm._v(" "+_vm._s(_vm.session.uid)+" ")])]),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"overline"},[_vm._v(" User ")]),_c('div',{attrs:{"data-test":"sessionUser-field"}},[_vm._v(" "+_vm._s(_vm.session.username)+" ")])]),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"overline"},[_vm._v(" Authenticated ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(_vm.session)?{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.session.authenticated)?_c('v-icon',_vm._g({attrs:{"color":_vm.session.active ? 'success' : '',"size":""}},on),[_vm._v(" mdi-shield-check ")]):_c('v-icon',_vm._g({attrs:{"color":"error","size":""}},on),[_vm._v(" mdi-shield-alert ")])]}}:null],null,true)},[(_vm.session.authenticated)?_c('span',[_vm._v("User has been authenticated")]):_c('span',[_vm._v("User has not been authenticated")])])],1),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"overline"},[_vm._v(" Ip Address ")]),_c('code',{attrs:{"data-test":"sessionIpAddress-field"}},[_vm._v(" "+_vm._s(_vm.session.ip_address)+" ")])]),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"overline"},[_vm._v(" Started ")]),_c('div',{attrs:{"data-test":"sessionStartedAt-field"}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.session.started_at))+" ")])]),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"overline"},[_vm._v(" Last Seen ")]),_c('div',{attrs:{"data-test":"sessionLastSeen-field"}},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.session.last_seen))+" ")])])])],1):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline primary"},[_vm._v(" Session ID error ")]),_c('v-card-text',{staticClass:"mt-4 mb-3 pb-1"},[_vm._v(" You tried to access a non-existing session ID. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.redirect}},[_vm._v(" Go back to sessions ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }